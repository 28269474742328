<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top pa-3">
      <v-card class="radius-card pa-3">
        <h3 class="mb-3">Request Counseling Permision</h3>
        <v-row>
          <v-col cols="3">
            <p class="mb-0">Filter Status</p>
            <v-select
              :items="listStatus"
              v-model="status"
              item-text="nama"
              item-value="val"
              dense
              hide-details
              outlined
              @change="fetchItems"
            ></v-select>
          </v-col>
          <v-col cols="7"></v-col>
          <v-col cols="2">
            <p class="mb-0">Sortir</p>
            <div class="d-flex align-center">
              <v-select
                :items="listSort"
                v-model="sort"
                item-text="nama"
                item-value="val"
                dense
                hide-details
                outlined
                @change="fetchItems"
              ></v-select>
            </div>
          </v-col>
        </v-row>
        <div>
          <div class="mt-3" v-if="list">
            <v-data-table
              :headers="headers"
              :items="list.data"
              hide-default-footer
              :page.sync="page"
              :items-per-page="limit"
              class="elevation-0 my-5"
            >
              <template v-slot:[`item.fullname`]="{ item }">
                <v-btn depressed class="mr-2" @click.prevent="viewPsy(item)">
                  <p class="ma-0 small_txt">
                    {{ item.psycholog ? item.psycholog.nama_lengkap : "" }}
                  </p>
                </v-btn>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  dark
                  :color="
                    item.status == 0
                      ? 'yellow'
                      : item.status == 1
                      ? 'green'
                      : 'red'
                  "
                >
                  {{
                    item.status == 0
                      ? "Pending"
                      : item.status == 1
                      ? "Aproved"
                      : "Rejected"
                  }}
                </v-chip>
              </template>
              <template v-slot:[`item.request`]="{ item }">
                <div class="d-flex">
                  <v-btn depressed class="mr-2" @click.prevent="viewReq(item)">
                    view request
                  </v-btn>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex">
                  <v-btn
                    dark
                    depressed
                    color="cyan"
                    class="mr-2"
                    style="min-width: 10px"
                    @click.prevent="updateItem(item)"
                  >
                    <v-icon dark small> mdi-cog </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>

            <div class="text-center pa-3">
              <v-pagination
                v-model="page"
                circle
                @input="fetchItems"
                :length="list.last_page"
              ></v-pagination>
            </div>
            <v-row>
              <v-col cols="2">
                <p class="mb-0">Tampilkan</p>
                <div class="d-flex align-center">
                  <v-select
                    :items="listLimit"
                    v-model="limit"
                    dense
                    hide-details
                    outlined
                    @change="fetchItems"
                  ></v-select>
                  <p class="ml-1 mb-0">Data</p>
                </div>
              </v-col>
            </v-row>
            <div class="pa-2">
              <change-status-counseling
                :dialogAlert="dialogAlert"
                :id="dataUser"
                @refetch="fetchItems"
                @close="dialogAlert = false"
              />
              <ProfilePsy
                :selected="inventory"
                :d_profilePsy="d_profilePsy"
                @close="d_profilePsy = false"
              />
              <ViewRek
                :inventory="inventory"
                @close="d_rek = false"
                :d_rek="d_rek"
              />
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import changeStatusCounseling from "../../components/Admin/Modal/changeStatusCounseling.vue";
import ViewRek from "./viewRek.vue";
import ProfilePsy from "../BATCH3/Counseling/Admin/profilePsy.vue";
export default {
  components: { changeStatusCounseling, ViewRek, ProfilePsy },
  name: "requestVerified",
  data() {
    return {
      d_profilePsy: false,
      list: null,
      inventory: null,
      listStatus: [
        {
          nama: "pending",
          val: "pending",
        },
        {
          nama: "accepted",
          val: "accepted",
        },
        {
          nama: "rejected",
          val: "rejected",
        },
        {
          nama: "all",
          val: "",
        },
      ],
      status: "",
      d_rek: false,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        {
          text: "NAME",
          align: "start",
          value: "fullname",
        },
        {
          text: "STATUS",
          align: "start",
          value: "status",
        },
        {
          text: "REQUEST DATA",
          align: "start",
          value: "request",
        },
        {
          text: "ACTIONS",
          align: "start",
          value: "actions",
        },
      ],
      page: 1,
      limit: 10,
      listLimit: [5, 10, 20, 50, 70, 100],
      dialogAlert: false,
      tab: "tab-1",
      dataUser: null,
      listSort: [
        { nama: "Terlama", val: "" },
        { nama: "Terbaru", val: "-created_at" },
      ],
      sort: "-created_at",
    };
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      let data = {
        page: this.page,
        limit: this.limit,
        status: this.status,
        sort: this.sort,
      };
      this.$store
        .dispatch("counseling/listReqPermission", data)
        .then((data) => {
          console.log(data);
          this.list = data;
        });
    },
    viewPsy(item) {
      this.inventory = item.psycholog;
      this.d_profilePsy = true;
    },
    updateItem(item) {
      console.log(item);
      this.dataUser = item.id;
      this.dialogAlert = true;
    },
    viewReq(item) {
      this.inventory = item.requirement;
      this.d_rek = true;
    },
  },
};
</script>

<style></style>
