<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAlert" persistent max-width="370px">
      <v-card class="radius-card hide_overflow" flat>
        <div class="d-flex justify-end px-2">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon></v-btn
          >
        </div>
        <div
          class="d-flex flex-column align-center justify-center"
          style="height: 230px"
        >
          <div>
            <div class="d-flex justify-center">
              <v-btn
                @click="changeItem(1)"
                class="text-capitalize py-6 mr-4"
                dark
                outlined
                color="purple"
                width="100px"
                depressed
                >Accept</v-btn
              >
              <v-btn
                class="text-capitalize py-6"
                @click="changeItem(2)"
                outlined
                color="purple"
                width="100px"
                depressed
                >Reject</v-btn
              >
            </div>
          </div>
          <v-overlay
            :absolute="true"
            color="white"
            opacity="1"
            :value="loading"
          >
            <v-progress-circular
              indeterminate
              class="mr-2"
              color="purple"
            ></v-progress-circular>
            <b class="purple--text">Loading...</b>
          </v-overlay>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "alertQuiz",
  props: ["dialogAlert", "id"],

  data() {
    return {
      role: "",
      loading: false
    };
  },
  methods: {
    changeItem(state) {
      this.loading = true;
      let body = {
        id: this.id,
        status: state
      };
      this.$store
        .dispatch("counseling/switchPermission", body)
        .then(data => {
          this.$emit("close");
          this.$emit("refetch");
          this.loading = false;
          Swal.fire({
            title: data.message,
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
            position: "top",
            toast: true
          });
        })
        .catch(err => {
          this.loading = false;

          console.log(err);
        });
    }
  }
};
</script>
